/**
 * Bio component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import React, { ComponentProps, forwardRef, Ref } from "react"

const GatsbyImage = forwardRef(
  (props: ComponentProps<typeof Image>, ref: Ref<Image>) => (
    <Image {...props} ref={ref} />
  )
)

export const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
        childImageSharp {
          fixed(width: 50, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author
          social {
            twitter
            github
          }
        }
      }
    }
  `)

  const { author, social } = data.site.siteMetadata

  return (
    <div className="flex mb-16">
      <div className="rounded-full m-0 mr-4">
        <GatsbyImage
          fixed={data.avatar.childImageSharp.fixed}
          alt={author}
          imgStyle={{ borderRadius: "50%" }}
        />
      </div>
      <div>
        <p className="mb-2">
          I, <strong>{author}</strong>, shall be writing up some projects.
          <br />
          I'm a full-stack software dev from <del>London</del>{" "}
          <ins>Brighton</ins>.
        </p>
        <p>
          <a
            className="link font-bold"
            href={`https://www.linkedin.com/in/pete-taylour/`}
          >
            [LinkedIn for work].
          </a>{" "}
          <a
            className="link font-bold"
            href={`https://github.com/${social.github}`}
          >
            [Github for code].
          </a>
        </p>
      </div>
    </div>
  )
}
